import config from '../../../config/config-default';

const getClientToken = async () => {
	const response = await fetch(`${config.apiUri}/bookings/?get-token=true`, {
		headers: {
			Accept: 'application/json',
		},
	});

	return response.json();
};

const loadScript = async (src) => {
	return new Promise((resolve) => {
		const script = document.createElement('script');
		script.async = true;
		script.defer = true;
		script.onload = () => {
			resolve();
		};
		document.head.appendChild(script);
		script.src = src;
	});
};

const initPayPal = async () => {
	const [token] = await Promise.all([
		getClientToken(),
		loadScript(
			'https://js.braintreegateway.com/web/3.82.0/js/client.min.js',
		),
		loadScript(
			'https://js.braintreegateway.com/web/3.82.0/js/paypal.min.js',
		),
	]);
	const clientInstance = await window.braintree.client.create({
		authorization: token.data,
	});
	const paypalInstance = await window.braintree.paypal.create({
		client: clientInstance,
	});
	window[config.globalScope].paypalInstance = paypalInstance;
};

const processPayPal = async (formData, submitForm, toggleButtons) => {
	try {
		const payload = await window[
			config.globalScope
		].paypalInstance.tokenize({
			flow: 'checkout',
			intent: 'sale',
			amount: +formData.total,
			currency: 'AUD',
			locale: 'en_AU',
			displayName: 'Garden Bags Bookings',
		});
		const paypalNonce = document.getElementById('paypal-nonce');
		paypalNonce.value = payload.nonce;
		submitForm();
	} catch (error) {
		if (error.code === 'PAYPAL_POPUP_CLOSED') toggleButtons(false);
	}
};

export { initPayPal, processPayPal };
