/* eslint-disable import/no-unresolved */
import formTemplate from 'bundle-text:./form.html';
import step1 from 'bundle-text:./step1/step1.html';
import step2 from 'bundle-text:./step2/step2.html';
import step3 from 'bundle-text:./step3/step3.html';
import step4 from 'bundle-text:./step4/step4.html';
import initStep1 from './step1/step1';
import initStep2 from './step2/step2';
import initStep3 from './step3/step3';
import initStep4 from './step4/step4';
import { toggleButtons, submitForm } from './submit/submit';
import { enableReCaptcha } from './submit/recaptcha';
import { initStripe } from './submit/stripe';
import { initPayPal } from './submit/paypal';
import { loadMaster } from '../template/template';

const formStepTemplates = { step1, step2, step3, step4 };
const initFormSteps = {
	step1: initStep1,
	step2: initStep2,
	step3: initStep3,
	step4: initStep4,
};

const enableValidation = () => {
	const form = document.getElementById('form');
	form.addEventListener('submit', (event) => {
		if (!form.checkValidity()) {
			event.preventDefault();
			event.stopPropagation();
		}

		form.classList.add('was-validated');
	});
};

const checkValidation = (step) => {
	const fieldset = document.getElementById(`step-${step}`);
	fieldset.classList.add('was-validated');

	/* const fields = [...fieldset.querySelectorAll('input, select, textarea')];
	for (let i = 0; i < fields.length; i += 1) {
		fields[i].checkValidity();
	} */

	return (
		fieldset.querySelectorAll(
			'input:invalid, select:invalid, textarea:invalid',
		).length === 0
	);
};

const clearValidation = (step) => {
	const fieldset = document.getElementById(`step-${step}`);
	fieldset.classList.remove('was-validated');
};

const loadForm = (embed = false) => {
	const existing = document.getElementById('steps');
	if (existing) return existing;

	const main = loadMaster(embed);
	main.innerHTML = formTemplate;

	if (embed) {
		document
			.getElementById('main-container')
			.classList.replace('container', 'container-fluid');
	}

	enableValidation();
	enableReCaptcha();
	initStripe(submitForm, toggleButtons);
	initPayPal();

	return document.getElementById('steps');
};

const loadFormStep = async (step, params = {}) => {
	if (![1, 2, 3, 4].includes(step)) return;

	const embed = (params || {}).embed === 'true';
	const steps = loadForm(embed);

	const otherSteps = [
		...steps.querySelectorAll(`fieldset:not(#step-${step})`),
	];
	for (let i = 0; i < otherSteps.length; i += 1) {
		otherSteps[i].style.display = 'none';
	}

	const existing = document.getElementById(`step-${step}`);
	if (existing) {
		existing.style.display = 'block';
		if (step === 4) {
			const paymentMethod = document.querySelector(
				'input[name="payment_method"]:checked',
			);
			if (paymentMethod) paymentMethod.dispatchEvent(new Event('change'));
		}
		return;
	}

	steps.insertAdjacentHTML('beforeend', formStepTemplates[`step${step}`]);
	initFormSteps[`step${step}`](params);
};

const stepCallback = (router) => {
	const steps = loadForm();
	const stepsData = steps.dataset;
	if (!stepsData.hasRouterListener) {
		stepsData.hasRouterListener = true;
		steps.addEventListener('click', (event) => {
			const element = event.target;
			const elementData = element.dataset;
			if (elementData.step) {
				event.preventDefault();
				event.stopPropagation();
				if (element.classList.contains('disabled')) return;
				if (elementData.step === 'prev') {
					router.navigate(element.getAttribute('href'));
				} else if (checkValidation(elementData.current)) {
					clearValidation(elementData.current);
					if (elementData.current === '4') {
						toggleButtons();
						submitForm();
					} else {
						router.navigate(element.getAttribute('href'));
					}
				}
			}
		});
	}
};

export { loadForm, loadFormStep, stepCallback };
