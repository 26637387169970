const config = {
	globalScope: 'gardenBagsBookings',
	apiUri: process.env.API_URI,
	reCaptchaKey: '6Ld4mS0UAAAAAPA8hS7l8hABCuYVMP7C39F06egU',
	stripeKey: process.env.STRIPE_KEY,
};

window[config.globalScope] = window[config.globalScope] || {};

export default config;
