import config from '../../../config/config-default';
import { toggleButtons } from '../submit/submit';

const updatePrice = (subtotal, surcharge) => {
	const formCharge = document.getElementById('charge');
	const formSurcharge = document.getElementById('surcharge');
	const formTotal = document.getElementById('total');
	const infoCharge = document.getElementById('info-charge');
	const infoSurcharge = document.getElementById('info-surcharge');
	const infoTotal = document.getElementById('info-total');

	const total = subtotal + surcharge;

	formCharge.value = subtotal.toFixed(2);
	formSurcharge.value = surcharge.toFixed(2);
	formTotal.value = total.toFixed(2);

	infoCharge.textContent = subtotal.toFixed(2);
	infoSurcharge.textContent = surcharge.toFixed(2);
	infoTotal.textContent = total.toFixed(2);
};

const calculatePrice = (includeSurcharge = false) => {
	const deliveryOption = document.getElementById('delivery-option');
	const paymentOption = document.getElementById('payment-option');
	const numberBinsBags = document.getElementById('number-bins-bags');

	const { paymentOptions } = window[config.globalScope];
	const charge =
		paymentOptions[deliveryOption.value] &&
		paymentOptions[deliveryOption.value][paymentOption.value]
			? paymentOptions[deliveryOption.value][paymentOption.value].amount
			: 0;
	const pickups =
		deliveryOption.value !== 'one-off' && paymentOption.value > 0
			? +paymentOption.value
			: 1;
	const binsBags = numberBinsBags.value > 0 ? +numberBinsBags.value : 1;

	const subtotal = charge * pickups * binsBags;
	const surcharge = includeSurcharge ? subtotal * 0.03 : 0;

	updatePrice(subtotal, surcharge);
};

const initStep4 = () => {
	calculatePrice();

	const paymentMethods = document.querySelectorAll(
		'input[name="payment_method"]',
	);
	const directDepositInfo = document.getElementById('direct-deposit-info');

	paymentMethods.forEach((radio) =>
		radio.addEventListener('change', () => {
			if (radio.value === 'Direct Deposit') {
				directDepositInfo.style.display = 'block';
			} else {
				directDepositInfo.style.display = 'none';
			}
			if (['Credit Card', 'PayPal'].includes(radio.value)) {
				calculatePrice(true);
			} else {
				calculatePrice();
			}
			toggleButtons(false);
		}),
	);
};

export default initStep4;
