import config from '../../../config/config-default';

const initStep2 = async () => {
	const addressType = document.getElementById('address-type');
	const tenantDetailsRow = document.getElementById('tenant-details-row');
	const tenantName = document.getElementById('tenant-name');
	const tenantPhone = document.getElementById('tenant-phone');
	const businessNameRow = document.getElementById('business-name-row');
	const businessName = document.getElementById('business-name');
	const region = document.getElementById('region');
	const suburb = document.getElementById('suburb');

	const response = await fetch(
		'https://bookings.gardenbagsbrisbane.com.au/json/regions-suburbs.json',
		{
			headers: {
				'Content-Type': 'application/json',
			},
		},
	);
	const json = await response.json();
	window[config.globalScope].regionsSuburbs = json;

	addressType.addEventListener('change', () => {
		if (addressType.value === 'Investment Property') {
			tenantDetailsRow.style.display = 'flex';
		} else {
			tenantDetailsRow.style.display = 'none';
			tenantName.value = '';
			tenantPhone.value = '';
		}
		if (addressType.value === 'Business Premises') {
			businessNameRow.style.display = 'flex';
		} else {
			businessNameRow.style.display = 'none';
			businessName.value = '';
		}
	});

	region.addEventListener('change', () => {
		const suburbs = json[region.value];
		if (suburbs) {
			let html = '<option value="">Please Select</option>';
			for (let i = 0; i < suburbs.length; i += 1) {
				const value = suburbs[i];
				html += `<option value="${value}">${value}</option>`;
			}
			suburb.innerHTML = html;
		}
	});
};

export default initStep2;
