import Navigo from 'navigo';
import { loadFormStep, stepCallback } from './form/form';
import { notFound } from './template/template';

const router = new Navigo('/');

router.hooks({
	before(done, match) {
		if (match.url !== '' && !document.getElementById('step-1')) {
			router.navigate('/', {
				historyAPIMethod: 'replaceState',
				callHooks: false,
			});
			done(false);
			return;
		}
		done();
	},
});

router.on({
	'/': ({ params }) => {
		loadFormStep(1, params).then(stepCallback(router));
	},
	'/step-2': () => {
		loadFormStep(2);
	},
	'/step-3': () => {
		loadFormStep(3);
	},
	'/step-4': () => {
		loadFormStep(4);
	},
});

router.notFound(() => {
	notFound();
});

export default router;
