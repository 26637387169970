import config from '../../../config/config-default';
import { toggleButtons, submitForm } from './submit';

const onloadCallback = () => {
	window.grecaptcha.render('recaptcha', {
		sitekey: config.reCaptchaKey,
		size: 'invisible',
		badge: 'inline',
		callback: window.recaptchaVerified,
		'error-callback': window.recaptchaError,
		'expired-callback': window.recaptchaExpired,
	});
};

const verifiedCallback = (token) => {
	const recaptchaToken = document.getElementById('recaptcha-token');
	recaptchaToken.value = token;
	submitForm();
};

const errorCallback = () => {
	window.grecaptcha.reset();
	toggleButtons(false);
};

const expiredCallback = () => {
	const successAlert = document.getElementById('success-alert');
	const cog = document.getElementById('submit-cog');
	if (
		successAlert.style.display === 'none' &&
		!cog.classList.contains('fa-spin')
	) {
		window.grecaptcha.reset();
		toggleButtons(false);
	}
};

const enableReCaptcha = () => {
	window.recaptchaOnload = onloadCallback;
	window.recaptchaVerified = verifiedCallback;
	window.recaptchaError = errorCallback;
	window.recaptchaExpired = expiredCallback;

	const script = document.createElement('script');
	script.async = true;
	script.defer = true;
	document.head.appendChild(script);
	script.src =
		'https://www.google.com/recaptcha/api.js?onload=recaptchaOnload&render=explicit';
};

export { enableReCaptcha };
