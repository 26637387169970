import config from '../../../config/config-default';

const loadScript = async () => {
	return new Promise((resolve) => {
		const script = document.createElement('script');
		script.async = true;
		script.defer = true;
		script.onload = () => {
			resolve();
		};
		document.head.appendChild(script);
		script.src = 'https://checkout.stripe.com/checkout.js';
	});
};

const initStripe = async (submitForm, toggleButtons) => {
	await loadScript();
	let tokenRetrieved = false;
	const stripeHandler = window.StripeCheckout.configure({
		key: config.stripeKey,
		locale: 'en-GB',
		token: (token) => {
			tokenRetrieved = true;
			const stripeToken = document.getElementById('stripe-token');
			stripeToken.value = token.id;
			submitForm();
		},
		closed: () => {
			if (!tokenRetrieved) toggleButtons(false);
		},
	});
	window[config.globalScope].stripeHandler = stripeHandler;
};

const processStripe = async (formData) => {
	const paymentOption = document
		.getElementById('payment-option')
		.querySelector('option:checked');
	window[config.globalScope].stripeHandler.open({
		name: 'Garden Bags Bookings',
		description: paymentOption.textContent,
		amount: formData.total * 100,
		currency: 'AUD',
		email: formData.email,
	});
};

export { initStripe, processStripe };
