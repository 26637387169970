import config from '../../../config/config-default';

const initStep1 = async (params = {}) => {
	const serviceRequired = document.getElementById('service-required');
	const deliveryOption = document.getElementById('delivery-option');
	const oneOff = document.getElementById('one-off');
	const paymentOption = document.getElementById('payment-option');
	const binsBagsRow = document.getElementById('bins-bags-row');
	const numberBinsBags = document.getElementById('number-bins-bags');
	const additionalBag = document.getElementById('additional-bag');
	const additionalBagFee = document.getElementById('additional-bag-fee');

	const response = await fetch(
		'https://bookings.gardenbagsbrisbane.com.au/json/payment-options.json',
		{
			headers: {
				'Content-Type': 'application/json',
			},
		},
	);
	const json = await response.json();
	window[config.globalScope].paymentOptions = json;

	serviceRequired.addEventListener('change', () => {
		if (serviceRequired.value === 'Garden Bins') {
			if (deliveryOption.value === 'one-off') {
				deliveryOption.value = '';
				deliveryOption.dispatchEvent(new Event('change'));
			}
			oneOff.setAttribute('disabled', '');
		} else {
			oneOff.removeAttribute('disabled');
		}
	});

	deliveryOption.addEventListener('change', () => {
		const paymentOptions = json[deliveryOption.value];
		if (paymentOptions) {
			const options = [...Object.entries(paymentOptions)];
			let html = '<option value="">Please Select</option>';
			for (let i = 0; i < options.length; i += 1) {
				const [value, option] = options[i];
				html += `<option value="${value}">${option.name}</option>`;
			}
			paymentOption.innerHTML = html;
		}
		if (deliveryOption.value === 'one-off') {
			binsBagsRow.style.display = 'none';
			numberBinsBags.removeAttribute('required');
			numberBinsBags.value = '';
		} else {
			binsBagsRow.style.display = 'flex';
			numberBinsBags.setAttribute('required', '');
		}
	});

	paymentOption.addEventListener('change', () => {
		const charge =
			json[deliveryOption.value] &&
			json[deliveryOption.value][paymentOption.value]
				? json[deliveryOption.value][paymentOption.value].amount
				: 0;
		if (charge > 0 && deliveryOption.value !== 'one-off') {
			additionalBagFee.textContent = charge;
			additionalBag.style.display = 'inline';
		} else {
			additionalBag.style.display = 'none';
		}
	});

	const qsParams = params || {};
	if (['bags', 'bins'].includes(qsParams.service)) {
		serviceRequired.value = `Garden B${qsParams.service.slice(1)}`;
		serviceRequired.dispatchEvent(new Event('change'));
	}
	if (qsParams.option) {
		deliveryOption.value = qsParams.option;
		deliveryOption.dispatchEvent(new Event('change'));
	}
	if (qsParams.bins) {
		paymentOption.value = qsParams.bins;
		paymentOption.dispatchEvent(new Event('change'));
	}
};

export default initStep1;
