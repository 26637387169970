const initStep3 = () => {
	const propertyTenanted = document.getElementById('property-tenanted');
	const landlordNameCell = document.getElementById('landlord-name-cell');
	const landlordName = document.getElementById('landlord-name');
	const agencyDetailsRow = document.getElementById('agency-details-row');
	const agencyName = document.getElementById('agency-name');
	const agencyPhone = document.getElementById('agency-phone');

	propertyTenanted.addEventListener('change', () => {
		if (propertyTenanted.value.substr(0, 14) === 'Yes, I am the ') {
			landlordNameCell.style.display = 'block';
			agencyDetailsRow.style.display = 'flex';
			landlordName.setAttribute('required', '');
			agencyName.setAttribute('required', '');
			agencyPhone.setAttribute('required', '');
		} else {
			landlordNameCell.style.display = 'none';
			agencyDetailsRow.style.display = 'none';
			landlordName.removeAttribute('required');
			agencyName.removeAttribute('required');
			agencyPhone.removeAttribute('required');
			landlordName.value = '';
			agencyName.value = '';
			agencyPhone.value = '';
		}
	});
};

export default initStep3;
