import config from '../../../config/config-default';
import { processStripe } from './stripe';
import { processPayPal } from './paypal';

const toggleButtons = (disable = true) => {
	const form = document.getElementById('form');
	const buttons = form.querySelectorAll('a[data-current="4"]');
	const cog = document.getElementById('submit-cog');
	buttons.forEach((button) => {
		if (disable) {
			button.classList.add('disabled');
		} else {
			button.classList.remove('disabled');
		}
	});
	if (disable) {
		cog.classList.add('fa-spin');
	} else {
		cog.classList.remove('fa-spin');
	}
};

const disableForm = (form) => {
	const paymentMethods = form.querySelectorAll(
		'input[name="payment_method"]',
	);
	const buttons = form.querySelectorAll('a[data-current="4"]');
	const cog = document.getElementById('submit-cog');
	paymentMethods.forEach((radio) => radio.setAttribute('disabled', ''));
	buttons.forEach((button) => {
		const { style } = button;
		style.display = 'none';
	});
	cog.style.display = 'none';
};

const getFormValues = (form) => {
	const fields = form.querySelectorAll('input, select, textarea');
	const formData = {};
	fields.forEach((field) => {
		if (
			field.name !== 'g-recaptcha-response' &&
			field.name !== '' &&
			(!['checkbox', 'radio'].includes(field.type) ||
				field.checked === true)
		)
			formData[field.name] = field.value;
	});

	return formData;
};

const executeReCaptcha = async () => {
	const successAlert = document.getElementById('success-alert');
	const errorAlert = document.getElementById('error-alert');
	successAlert.style.display = 'none';
	errorAlert.style.display = 'none';
	toggleButtons();
	window.grecaptcha.execute();
};

const postData = async (formData) => {
	const response = await fetch(`${config.apiUri}/bookings/`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(formData),
	});

	return response.json();
};

const showResult = (json) => {
	const type = json.success ? 'success' : 'error';
	const alert = document.getElementById(`${type}-alert`);
	const message = document.getElementById(`${type}-message`);

	message.textContent = json.message;
	alert.style.display = 'block';

	if (!json.success) document.getElementById('stripe-token').value = '';
};

const submitForm = async () => {
	const form = document.getElementById('form');
	const formData = getFormValues(form);

	if (formData.payment_method === 'Credit Card' && !formData.stripe_token) {
		processStripe(formData);
		return;
	}
	if (formData.payment_method === 'PayPal' && !formData.paypal_nonce) {
		processPayPal(formData, submitForm, toggleButtons);
		return;
	}

	const recaptchaToken = document.getElementById('recaptcha-token');
	if (recaptchaToken.value === '') {
		executeReCaptcha();
		return;
	}

	const json = await postData(formData);

	if (json.success) {
		disableForm(form);
	} else {
		window.grecaptcha.reset();
		document.getElementById('recaptcha-token').value = '';
		toggleButtons(false);
	}
	showResult(json);
};

export { toggleButtons, submitForm };
