/* eslint-disable import/no-unresolved */
import masterTemplate from 'bundle-text:./master/master.html';
import notFoundTemplate from 'bundle-text:./404/404.html';

const loadMaster = (embed = false) => {
	const existing = document.getElementById('main');
	if (existing) return existing;

	const app = document.getElementById('app');
	app.insertAdjacentHTML('afterbegin', masterTemplate);

	if (embed) {
		document.getElementById('header').style.display = 'none';
		document.getElementById('footer').style.display = 'none';
		document.getElementById('main').classList.add('embedded');
	}

	return document.getElementById('main');
};

const notFound = () => {
	const main = loadMaster();
	main.innerHTML = notFoundTemplate;
};

export { loadMaster, notFound };
